<template>
  <div class="mx-auto max-w-[96rem]">
    <div class="flex justify-end mb-20">
      <dropdown
        v-model="selectedVersion"
        :options="versions"
        containerClass="ml-auto"
        :dropdownProps="{
          size: 'lg',
          variant: 'line',
          distance: 4,
          offset: [0, 0],
          placement: 'bottom-end'
        }"
        closeOnClick
        @update:modelValue="onChangeVersion"
      />
    </div>
    <div
      class="studio-scrollbar-4 rounded-[4rem] bg-surface-elevation-1 py-[4.2rem] px-[5rem] border-1 border-solid border-border shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] h-[calc(100vh-49.4rem)] min-h-[62rem]"
    >
      <h1 class="text-xl font-bold text-on-surface-elevation-2 leading-xl">
        {{ selectedTermContent?.title }}
      </h1>
      <safe-html tag="div" :html="selectedTermContent?.text ?? ''" class="mt-16" />
    </div>

    <div v-if="showCheckBox" class="mt-40 flex items-center flex-col justify-center gap-16">
      <s-checkbox v-if="isRequiredTerm" v-model="reAgree" class="mt-16">
        <span class="font-medium">{{
          $t('studio.group.pre_grp_landing.new_term_agreement_chkbox')
        }}</span>
      </s-checkbox>
      <s-button
        :disabled="!reAgree"
        size="lg"
        variant="primary"
        class="!min-w-160"
        @click="onConfirm"
      >
        {{ $t('studio.common.popup_case_cf_btn') }}
      </s-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  agreeWithSubscriptionTermsAndConditionApi,
  fetchServiceTermsAndConditionHistoryApi,
  fetchTermContentApi
} from '@/apis/terms.api';
import SafeHtml from '@/components/common/safe-html.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { PLATFORM, SERVICE_ID, SERVICE_TYPES, VIEW_AREA } from '@/constants/common.const';
import { YEAR_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { GROUP_REGISTER_PAGE_URL, HOME_PAGE_URL } from '@/constants/url.const';
import { TermAgreeType } from '@/enums/terms.enum';
import { useTermsStore } from '@/stores/terms.store';
import { useUserStore } from '@/stores/user.store';
import type { FormOption } from '@/types/common/form.type';
import type { SimpleGroupResponse } from '@/types/my-info/my-info-response.type';
import type { ContentModel } from '@/types/terms/terms-model.type';
import type { DetailContentResponse, TermHistory } from '@/types/terms/terms-response.type';
import { redirectTo } from '@/utils/common.util';
import { formatDateTime } from '@/utils/date.util';
import { definePageMeta, showAlert } from '#imports';

definePageMeta({
  layout: 'empty',
  middleware: ['check-login-middleware', 'check-user-age-middleware']
});
const termsStore = useTermsStore();
const userStore = useUserStore();
const { fetchRemainingStudioTerms, setIsNotAgreeAllTerms } = termsStore;
const { remainingStudioTerms, isNotAgreeAllTerms, firstSignUpNation } = storeToRefs(termsStore);
const { selectedGroupId, joinedGroups } = storeToRefs(userStore);

const { locale, t } = useI18n();
const lastVersion = ref<number>();
const selectedVersion = ref<number>();
const versions = ref<FormOption<number>[]>([]);

const showCheckBox = computed(() => {
  return versions.value.length !== 0 && selectedVersion.value === lastVersion.value;
});

const subCategories = ref<string[]>([]);

const currentCategoryTermIndex = ref<number>(0);
const selectedTermContent = ref<DetailContentResponse>();
const reAgree = ref<boolean>(true);

const isRequiredTerm = computed(() => {
  const currentTerm = remainingStudioTerms.value.filter(
    (item: ContentModel) => item.contentsNo === selectedVersion.value
  );
  return (
    currentTerm[0] &&
    (currentTerm[0].agreeType === TermAgreeType.ALWAYS_MUST ||
      currentTerm[0].agreeType === TermAgreeType.FIRST_MUST)
  );
});

const getRemainingStudioTerms = async () => {
  await fetchRemainingStudioTerms(locale.value);
  subCategories.value = remainingStudioTerms.value.map((item: ContentModel) => item.subCategory);
};

const getTermHistory = async () => {
  const subCategory = subCategories.value[currentCategoryTermIndex.value];
  const result = await fetchServiceTermsAndConditionHistoryApi({
    serviceType: SERVICE_TYPES.GAME_ID,
    serviceId: SERVICE_ID.INDIEGAME,
    nation: firstSignUpNation.value,
    platform: PLATFORM.PC,
    lang: locale.value,
    subCategory
  });
  if (!result || !result.contentsList) {
    return;
  }

  const latestContentsList = result.contentsList
    .sort((a: TermHistory, b: TermHistory) => b.enforcedDt - a.enforcedDt)
    .slice(0, 5);

  versions.value = latestContentsList.map((item: TermHistory) => ({
    value: item.contentsNo,
    label: `${formatDateTime(item.enforcedDt, YEAR_FORMAT_WITH_DOT)} (v${item.versionIdx})`
  }));

  lastVersion.value = versions.value[0].value;
  selectedVersion.value = versions.value[0].value;
  await getTermContent(selectedVersion.value);
};

const getTermContent = async (contentNo?: number) => {
  // call api by contentNo
  if (contentNo === undefined) {
    return;
  }
  const result = await fetchTermContentApi(contentNo);
  if (!result) {
    return;
  }
  selectedTermContent.value = result;
};

const onChangeVersion = async (value: number | string) => {
  selectedVersion.value = Number(value);
  await getTermContent(selectedVersion.value);
};

const getTheNearestJoinedGroup = (): string => {
  if (!Array.isArray(joinedGroups.value) || joinedGroups.value.length === 0) {
    return '';
  }
  const nearestGroup = joinedGroups.value.reduce(
    (prev: SimpleGroupResponse, current: SimpleGroupResponse) => {
      return prev.joinedAt > current.joinedAt ? prev : current;
    }
  );
  return nearestGroup.groupId;
};

const onConfirm = async () => {
  if (currentCategoryTermIndex.value !== subCategories.value?.length - 1) {
    currentCategoryTermIndex.value += 1;
    await getTermHistory();
    return;
  }
  const result = await agreeWithSubscriptionTermsAndConditionApi({
    serviceInfos: [
      {
        serviceId: SERVICE_ID.INDIEGAME
      }
    ],
    viewareaId: VIEW_AREA.STDJOIN,
    nation: firstSignUpNation.value
  });
  if (!result) {
    return;
  }
  setIsNotAgreeAllTerms(false);
  if (selectedGroupId.value) {
    await redirectTo(HOME_PAGE_URL, { groupId: selectedGroupId.value });
    return;
  }
  await redirectTo(GROUP_REGISTER_PAGE_URL);
};

const init = async () => {
  if (!isNotAgreeAllTerms.value) {
    await showAlert({
      severity: 'info',
      content: `${t('studio.group.pre_grp_landing.all_terms_agreed_pop_msg1')}<br/>${t(
        'studio.group.pre_grp_landing.all_terms_agreed_pop_msg2'
      )}`, // i18n
      confirmVariant: 'blue',
      cancelVariant: 'outline'
    });
    const nearestJoinedGroupId = getTheNearestJoinedGroup();
    if (nearestJoinedGroupId) {
      return await redirectTo(`/${locale.value}${HOME_PAGE_URL}`, {
        groupId: nearestJoinedGroupId,
        withLocale: true,
        external: true
      });
    }
    return await redirectTo(GROUP_REGISTER_PAGE_URL);
  }

  await getRemainingStudioTerms();
  await getTermHistory();
};

init();
</script>
